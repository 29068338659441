
import { defineComponent, ref, onMounted, reactive, UnwrapRef } from 'vue'
import { HomeOutlined } from '@ant-design/icons-vue'
import { notification } from 'ant-design-vue'

import keyService from '../services/key'
import commonServices from '../services/common'

declare const Stripe
declare const paypal
interface PaymentIntent {
  amount: number;
  currency: string;
}
export default defineComponent({
  name: 'Dashboard',
  components: {
    HomeOutlined
  },
  setup () {
    const loading = ref<boolean>(false)
    const stripePaymentFormRef = ref()
    const paypalPaymentFormRef = ref()
    const card = ref()
    const paymentKeys = ref()
    const errorMessage = ref<string>('')
    const transactionSuccess = ref<boolean>(false)
    const stripe = ref()
    const elements = ref()
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({
        message,
        description
      })
    }
    const stripeForm: UnwrapRef<PaymentIntent> = reactive({
      amount: 0,
      currency: 'GBP'
    })
    const paypalForm: UnwrapRef<PaymentIntent> = reactive({
      amount: 0,
      currency: 'GBP'
    })
    const style = {
      base: {
        fontSize: '16px',
        color: '#32325d'
      }
    }
    const configurePaypal = async () => {
      paypal.Buttons({
        style: {
          layout: 'vertical',
          color: 'blue',
          shape: 'rect',
          label: 'paypal'
        },
        createOrder: function (data, actions) {
          // This function sets up the details of the transaction, including the amount and line item details.
          return actions.order.create({
            purchase_units: [{
              amount: {
                currency_code: paypalForm.currency,
                value: paypalForm.amount
              }
            }]
          })
        },
        onApprove: function (data, actions) {
          // This function captures the funds from the transaction.
          return actions.order.capture().then(function (details) {
            // This function shows a transaction success message to your buyer.
            console.log('transaction completed', details)
          })
        },
        onCancel: function (data) {
          console.log('canceled', data)
        }
      }).render('#paypal-button-container')
    }
    const configureStripe = async () => {
      const stripeKey = paymentKeys.value.filter(key => key.type === 'Stripe')
      if (stripeKey.length === 0) errorMessage.value = 'Stripe public key is not configured'
      stripe.value = Stripe(stripeKey[0].key)
      elements.value = stripe.value.elements()
      card.value = elements.value.create('card', { style })
      card.value.mount('#card-element')
    }
    const getGatewaySettings = async () => {
      try {
        const responce = await keyService.getKeys()
        paymentKeys.value = responce.data
        console.log('paymentKeys', paymentKeys.value)
        configureStripe()
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    const generateStripeSecret = async () => {
      try {
        loading.value = true
        transactionSuccess.value = false
        errorMessage.value = ''
        stripeForm.amount = +stripeForm.amount
        const profile = commonServices.getCurrentProfile()
        const responce = await keyService.generateStripeSecretToken(stripeForm)
        if (typeof responce.data === 'string') {
          errorMessage.value = responce.data
          loading.value = false
          return false
        }
        const result = await stripe.value.confirmCardPayment(responce.data.client_secret, {
          payment_method: {
            card: card.value,
            billing_details: {
              name: `${profile.first_name} ${profile.last_name}`,
              email: profile.email
            }
          }
        })
        console.log('result', result)
        loading.value = false
        // Save transaction details to DB
        if (result.error) {
          errorMessage.value = result.error.message
        } else {
          if (result.paymentIntent.status === 'succeeded') {
            transactionSuccess.value = true
          }
        }
      } catch (error) {
        notify('Error', error.data, 'error')
      }
    }
    onMounted(() => {
      getGatewaySettings()
      configurePaypal()
    })
    return {
      loading,
      stripeForm,
      paypalForm,
      stripePaymentFormRef,
      paypalPaymentFormRef,
      generateStripeSecret,
      errorMessage,
      transactionSuccess
    }
  }
})
